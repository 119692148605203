'use client';

// Libraries
import { Spinner } from 'react-bootstrap';

export default function Loading() {
  return (
    <div className="global-spinner">
      <Spinner animation="border" variant="primary" role="status" />
    </div>
  );
}
