'use client';

// Libraries
import dynamic from 'next/dynamic';

// Constants
import { LAYOUT } from '@/constants/config';

// Components
import Loading from '@/components/Loading';

// Variables
const TopBanner = dynamic(() => import('@/components/Home/TopBanner'), {
  ssr: false,
  loading: () => <Loading />,
});
const Products = dynamic(() => import('@/components/Home/Products'), {
  ssr: false,
  loading: () => <Loading />,
});
const HotelManagement = dynamic(
  () => import('@/components/Home/HotelManagement'),
  {
    ssr: false,
    loading: () => <Loading />,
  }
);
const ChannelManager = dynamic(
  () => import('@/components/Home/ChannelManager'),
  {
    ssr: false,
    loading: () => <Loading />,
  }
);
const Clients = dynamic(() => import('@/components/Home/Clients'), {
  ssr: false,
  loading: () => <Loading />,
});
const Partners = dynamic(() => import('@/components/Home/Partners'), {
  ssr: false,
  loading: () => <Loading />,
});
const ContactUs = dynamic(() => import('@/components/ContactUs'), {
  ssr: false,
  loading: () => <Loading />,
});

export default function Home() {
  return (
    <article className="home">
      <TopBanner />
      <Products />
      <HotelManagement />
      <ChannelManager />
      <Clients />
      <Partners />
      <ContactUs />
    </article>
  );
}

Home.layout = LAYOUT.HEADER_TRANSPARENT;
Home.title = 'common.meta.page.home';
